import React from "react"
import ReactResizeDetector from "react-resize-detector"
import * as Scroll from "react-scroll"
import { Link, navigate } from "gatsby"

import SiteLogo from "../../../content/assets/prosource-logo-color-centre.png"
import {responsive} from "../../utils/constants"

import {PLink, FooterCard} from "./base"

let scroll = Scroll.animateScroll;

function HandleResize (width, height){
    const {mobile, tablet} = responsive;
    if(width >= tablet){
        return {isMobile: false, isTablet: false, isDesktop : true};
    }
    else if(width >= mobile && width <= tablet){
        return {isMobile: false, isTablet: true, isDesktop : false};
    }
    else{
        return {isMobile: true, isTablet: false, isDesktop : false};
    }
}

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isMobile : false,
            isTablet : false,
            isDesktop: true
        }

        this.handleFooterLinkClick = this.handleFooterLinkClick.bind(this)
    }

    onResize = (width, height) => { this.setState(HandleResize(width, height)) }

    handleFooterLinkClick = event => {
      event.preventDefault();
      const {headerActive} = this.props;
      const navigateLinkText = event.currentTarget.dataset.text, navigateLink = event.currentTarget.getAttribute("href"), SiteWrapper = document.getElementById("site-wrapper");

      if(navigateLink){
        if(headerActive === navigateLinkText){
          scroll.scrollToTop()
        }
        else{
          if(SiteWrapper) SiteWrapper.classList.add("animated", "fadeOut")
          setTimeout(()=>{
            navigate(navigateLink)
          }, 1000)
        }
      }
    }

    render() {
      /* Footer Content */
        const Expertise = [
          { 
            "text" : "Distributed Teams",
            "link" : "/distributed-teams"
          },
          {
            "text" : ".Net",
            "link" : "/expertise/dotnet"
          },
          {
            "text" : "AWS",
            "link" : "/expertise/aws"
          },
          {
            "text" : "Angular",
            "link" : "/expertise/angular"
          },
          {
            "text" : "C#",
            "link" : "/expertise/csharp"
          },
          {
            "text" : "Dev Ops",
            "link" : "/expertise/devops"
          },
          {
            "text" : "iOS / Android",
            "link" : "/expertise/ios"
          },
          {
            "text" : "Java",
            "link" : "/expertise/java"
          },
          {
            "text" : "NodeJS",
            "link" : "/expertise/nodejs"
          },
          {
            "text" : "PHP",
            "link" : "/expertise/php"
          },
          {
            "text" : "ReactJS",
            "link" : "/expertise/react"
          },
          {
            "text" : "Salesforce",
            "link" : "/expertise/salesforce"
          },
        ];

        const Sections = [
            {
                "text" : "Approach", "link" : "approach"
            },
            {
                "text" : "About", "link" : "about"
            },
            {
                "text" : "Campus", "link" : "campus"
            },
            {
                "text" : "Careers", "link" : "careers"
            }
        ];

        const Locations = [
            {
                "country" : "Philippines",
                "address" : `Levels 19, 18 & 17 SocialDesk Tower \n102 HV Dela Costa Street Makati, NCR Philippines`
            },
            {
                "country" : "Hong Kong",
                "address" : `11th Floor, Wing On Plaza, \n62 Mody Road TST, Hong Kong`
            }
        ]

        const Div2 = [
            {
                "title" : "New Distributed Teams",
                "info" : "Steve Burns\n(+63) 908-820-9309",
                "email" : "sburns@prosourcebpo.com"
            },
            {
                "title" : "Join Us",
                "email" :  "recruitment@prosourcebpo.com"
            },
            {
                "title" : "Anything Else",
                "email" :  "hello@prosourcebpo.com"
            }
        ]

        const SocialMediaLinks = [
          {
            "text" : "Facebook",
            "link" : "https://www.facebook.com/prosourcebpo/"
          },
          {
            "text" : "LinkedIn",
            "link" : "https://www.linkedin.com/company/prosource-global-corporation/"
          }
        ]
        
        const {isMobile} = this.state;

        /* Footer Parts */

        const CopyrightDiv = <>
            <div> &copy; {new Date().getFullYear()} ProSource BPO &#183; All rights reserved.</div>
            <span> Made in Manila &#183; By Developers For Developers</span>
        </>

        const SectionsDiv = <div className="footer-section-div">
            {
                Sections.map(({text, link}) => {
                    return <Link to={link} onClick={this.handleFooterLinkClick} className={"p-link p-link--footer"} data-text={text}> {text} </Link>
                })
            }
        </div>

        const SocialMediaDiv = <div className="footer-social-div">
          {
            SocialMediaLinks.map(({text, link}) =>{
              return <a href={link} className={`p-link p-link--footer`}>{text}</a>
            })
          }
        </div>

        // const DesktopFooterB = <><div className="col-sm-6 footer-b1">
        //       <FooterCard whiteSpaceSpan="unset">{CopyrightDiv}</FooterCard>
        //     </div>
        //     <div className="col-sm-6 footer-b2">
        //       <FooterCard whiteSpaceSpan="unset"> 
        //         {SectionsDiv} {SocialMediaDiv}
        //       </FooterCard>
        //     </div></>
    

        // const MobileFooter = <div className="col-sm-12" style={{textAlign: "center"}}>
        //       <FooterCard whiteSpaceSpan="unset"> 
        //         {SectionsDiv} {SocialMediaDiv}
        //       </FooterCard>
        //       <FooterCard whiteSpaceSpan="unset">{CopyrightDiv}</FooterCard>
        //     </div>

        return (
            <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
                <footer className={`site-foot${isMobile ? " site-foot-mobile" : ""}`} id="site-foot">
                    <div className="footer-a scroller-adjustment">
                        <div>
                            <FooterCard>
                                <img src={SiteLogo} alt="Logo" className="site-head-logo"/>
                            </FooterCard>
                            <div className="footer-a--locations">
                                {
                                    Locations.map(({country, address}) =>{
                                        return  <FooterCard cardTitle={country}>{address}</FooterCard>
                                    })
                                }
                            </div>
                        </div>

                        <div className="footer-a--contact">
                            {
                                Div2.map(({title, info, email}) =>{
                                    return <FooterCard cardTitle={title}>
                                        {info ? info : ""}
                                        {email ? <span><a href={`mailto:${email}`} className={`p-link p-link--hover-dashed`}>{email}</a></span> : ""}
                                    </FooterCard>
                                })
                            }
                        </div>

                        <div>
                            <FooterCard cardTitle={`Expertise`} style={{marginBottom: `0px`}}></FooterCard>
                            <div className="footer-card expertise-footer-card">
                                {Expertise.map(({text, link}) =>{
                                  return <PLink to={link} className={`p-link`} onClickFadeOut={true} ignoreScrollTop={true}>{text}</PLink>
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="footer-hr scroller-adjustment"/>
                                
                    <div className="footer-b scroller-adjustment">
                        <div className="footer-b--copyright">
                            <FooterCard whiteSpaceSpan="unset">{CopyrightDiv}</FooterCard>
                        </div>
                        <div className="footer-b--links">
                            <FooterCard whiteSpaceSpan="unset">  {SectionsDiv} {SocialMediaDiv}  </FooterCard>
                        </div>
                    </div>
                </footer>
            </ReactResizeDetector>

        )
    }
}

export default Footer